.animation-spinning {
    animation: spinning 4s steps(8, end) infinite;
}

// @keyframes spinning {
//     $start:0;
//     $end:7;
//     @for $i from $start through $end {
//          #{$i}% { transform: rotate(calc(#{$i}*(360deg / #{$end})))}
//     }
// }

@keyframes spinning {
    to {
        transform: rotate(360deg);
    }
}