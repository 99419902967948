&::-webkit-scrollbar {
    width: 15px;
    border-radius: 5px;
}

/* Track */
&::-webkit-scrollbar-track {
    background: $mainBg;
    box-shadow: inset #555 2px;
    border-radius: 5px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    background: $mainThird;
    border-radius: 3px;
    
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
    background: $mainFourth;    
}