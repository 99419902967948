section {
    min-height: 450px;
    width: 100%;
    background: $lightThird;
    color: #5a2191;

    &.light-secondary {
        background-color: $lightSecondary;
    }
}

.landing {
    font-family: 'Quicksand', sans-serif;
    .hero {
        min-height: calc(100vh - 100px);
        width: 100%;
        position: relative;
        /* The image used */
        background-image: url('/img/dormilonez_hero_background.jpg');
    
        /* Full height */
        height: 100%; 
    
        /* Create the parallax scrolling effect */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .logo {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 42%;
            left: 50%;
            z-index: 2;            
            max-width: 500px;
            height: auto;
        
                img {
                    width: 100%;
                    height: auto;
                }
        }
    }

    h1 {
        letter-spacing: 0.1rem;
    }
    h2 {
        &.big {
            // font-size: 2.3rem;
            letter-spacing: 0.05rem;

        }
    }
    p {
        &.big {
            font-size:1.3rem;
        }        
        &.mid {
            // font-size:1.15rem;
        }
        text-align: justify;
    }
    .welcome {
        h1 {
            font-size: 2rem;
            letter-spacing: 0.1rem;
        }

    }

    .about {
        .overlapped-images {
            display: flex;
            @media (min-width: 992px) {
                position: relative;
                padding: 0 0 !important;
            }
            @media (min-width: 768px) {
                position: relative;
                padding: 100px 0;
            }
            img {
                &.circle {
                    overflow: hidden;
                    border-radius: 50%;
                    border: solid 5px $primary;
                }
            }
            
            .big-img {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 15px;
                width: 60%;                
                @media (min-width: 768px) {
                    position: absolute;
                    width: 80%;                
                    left: 20%;
                    max-width: 300px;

                }

                height: auto;
            }

            .small-img {
                margin-right: auto;
                margin-left: auto;
                width: 50%;
                @media (min-width: 768px) {
                    position: absolute;
                    width: 45%;
                    top: 250px;
                    right: 0;    
                }
                @media (min-width: 992px) {
                    position: absolute;
                    width: 50%;
                    top: 175px;
                    right: 0;    
                }

                height: auto;
            }
            
        }
    }

    .formation {
        h2 {
            font-family: 'Ubuntu', sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.3rem;
            @media (min-width: 762px) {
                // // font-size: 3rem;
                font-weight: 700;
                letter-spacing: 0.3rem;
            }

            text-align: center;
        }

        ul{
            height: 100%;
            &.formation-list {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding-top: 2rem;
                padding-bottom: 2rem;
                margin-bottom: 0;
               li {
                   padding: 0.5rem 0;
                } 
            }
            &.certification-list {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                li {
                    display: flex;
                    img {
                        object-fit: contain ;
                        width: 100%!important;
                        height: auto;
                        max-height: 135px;
                        max-width: 135px;
                        margin-left: auto;
                        margin-right: auto;    
                    }
                }
            }
        }

        .certifications-box {
            @include media-breakpoint-up(md) {
                border-right: 1px solid 
            }

        }

        .owl-carousel {
            position: relative;
            .owl-stage{
                height: 100%;
            }
            .owl-item {
                min-height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 300px;
                img {
                    object-fit: contain ;

                    width: 100%!important;
                    height: auto;
                    max-height: 300px;
                    max-width: 300px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .owl-nav{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                pointer-events: none;

                button {
                    width: 25%;   
                    pointer-events: initial;
                }
            }
        }
    }
    .services {
        padding: 2rem 0;
        h2 {
            font-family: 'Ubuntu', sans-serif;
            text-transform: uppercase;
            // font-size: 2.2rem;
            font-weight: 700;
            letter-spacing: 0.05rem;
            @media (min-width: 762px) {
                // font-size: 3rem;
                font-weight: 700;
                letter-spacing: 0.1rem;
            }

            text-align: center;
        }

        .services-grid {
            h2 {
                font-family: 'Ubuntu', sans-serif;
                text-transform: uppercase;
                text-align: center;
            }
            .service-container {
                margin: 10px 0;
                .box {
                    height: 180px;
                    overflow: hidden;
                    background: $primary-color;
                
                    .box-img {
                        object-fit: contain;
                        object-position: center;
                    }
                }
                
            }
    
        }

        .container {
            
        }

        .age-select {
            margin: 0;
            .service-selection-card {
                overflow: hidden;
                position: relative;
                padding: 0;
                background-color:$mainFourth-60;

                h3 {
                    // position: absolute;
                    // bottom: 0;
                    // right: 0;
                    text-align: end;
                    
                    padding: 15px;
                    z-index: 1;
                    margin-bottom: 0;
                    width: 100%;
                    font-size: small;
                    transition: all 0.3s ease-in-out;

                    @include media-breakpoint-up(md) {
                        padding: 1.5em 0.5em;
                        font-size: medium;
                    }

                    color: white;
                    &.first{
                        text-align: start;
                    }


                }
            }
            a {
                text-align: center;
                color: inherit;
                text-decoration: none;
                
                &:hover {
                    text-decoration: none;

                    .service-img-container {
                        img {
                            transform: scale(1.1);
                            box-shadow: 0 0 5px 5px rgba(0,0,0,0.05);
                        }
                    }

                    h3 {
                        font-size: medium;
                        @include media-breakpoint-up(md) {
                            font-size: large;
                        }
                    }
                }
                .service-img-container {
                    padding: 0;
                    overflow: hidden;
                    img {
                        // box-shadow: 0 0 10px 10px rgba(0,0,0,0.1);
                        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                        // border-radius: 500px;
                        // border: $mainFourth 3px solid;
                        z-index: -5;
                    }
                }
                
            }
        }
    
    }
    
    .sleep-importance {
        color: $white;
        background-image: url('/img/bg-sleep.jpg');
        background-position: center;
        background-size: cover;
          /* Create the parallax scrolling effect */
        // background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        position: relative;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(#fff,0.3);
            background: rgba(197, 145, 253, 0.747);
            z-index: 1;
        }

        * {
            color: inherit;
            z-index: 3;
        }

        h2 {
            font-family: 'Ubuntu', sans-serif;
            // font-size: 2.2rem;
            font-weight: 700;
            letter-spacing: 0.1rem;
            color: inherit;
            @media (min-width: 762px) {
                // font-size: 3rem;
                font-weight: 700;
                letter-spacing: 0.2rem;
            }

            text-align: center;
        }        
    }

    .sleep-pillars {
        margin: 0;
        padding: 0;
        overflow: hidden;
        min-height: 100px;
        
        .image-bg {
            margin-left: -30px;
            margin-right: -30px;
            display: flex;
            justify-content: center;
            @media (min-width: 768px) {
                margin: 0; 
            }
            img {
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .certified {
        padding: 2rem 0;

        h3 {

            
            // font-size: 2rem;
            
            color: inherit;
            @media (min-width: 762px) {
                // font-size: 2.5rem;

            }

            text-align: center;   
        }
    }

    .testimonies {
        padding: 0;
        height: 100%;
        .owl-carousel {
            padding:0;
            position: relative;
            color: $mainFourth;
            .owl-stage{
                height: 100%;
            }
            .owl-item {
                @media (min-width: 768px) {
                    padding: 7rem 0.5rem;
                    padding-bottom: 9rem;
                }
                padding: 1rem 0.5rem;
                padding-bottom: 2rem;

                min-height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:nth-child(2n){
                    background: rgb(194,175,235);
                    color: $white;
                }
                
                .container {
                    object-fit: contain ;
                    width: 100%!important;
                    height: auto;
                    padding: 2.5rem;
                    @media (min-width: 768px) {
                        margin-left: 26%;
                        margin-right: auto;
                    }
                    max-width: 360px;
                }

                .container-odd {
                    object-fit: contain ;
                    width: 100%!important;
                    height: auto;
                    padding: 2.5rem;
                    @media (min-width: 768px) {
                        margin-right: 26%;
                        margin-left: auto;
                    }
                    max-width: 360px;
                }
            }
            .owl-nav{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                pointer-events: none;
                button {
                    pointer-events: initial;
                    width: 25%;   
                    span {
                        font-weight: 300;
                        font-size: xx-large;
                    }
                }
            }

            .owl-dots {
                position: absolute;
                bottom: 1rem;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                height: 3rem;
                color: $mainFourth;
            
                .owl-dot {
                    display: inline-block;
                    zoom: 1;
                    &.active {
                        span {
                            background: $mainFourth;
                        }
                    }
                    span {
                        width: 10px;
                        height: 10px;
                        margin: 5px 7px;
                        background: $mainThird;
                        display: block;
                        -webkit-backface-visibility: visible;
                        backface-visibility: visible;
                        transition: opacity .2s ease;
                        border-radius: 30px;
                    }
                }
            }

            .quotations {
                // font-size: 5rem;
            }

            .author {
                font-weight: 700;
            }
        }
    }

    .instagram-section {
        background: $lightSecondary;
        padding: 2rem 0;

        h3 {
            font-family: 'Ubuntu', sans-serif;
            // font-size: 2.2rem;
            font-weight: 300;
            letter-spacing: 0.1rem;
            color: inherit;
            @media (min-width: 762px) {
                // font-size: 3rem;
                letter-spacing: 0.1rem;
            }
            a {
                color: inherit;
                text-decoration: none;
                font-weight: 500;
                font-style: italic;

                &:hover{
                    color: inherit;
                    text-decoration: none;
                }
            }
            text-align: center;
        }     
    }

    .instagram-section {
        #instagram-feed1 {
            .instagram_gallery {
                a {
                    img {
                        transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;                
                    
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }    
        }
    }
    
}
