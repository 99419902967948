.faqs {
    color: $white;

    .title {
        color: $mainFourth;
    }
    .dialogue_box {
        clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 90% 95%, 95% 100%, 80% 95%, 0% 95%);
        background-color: $mainFourth;
        width: 100%;

        @media (min-width: 768px) {
            clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 90% 90%, 95% 100%, 80% 90%, 0% 90%);
            width: 80%;
            margin-left: auto;

            margin-bottom: 5vh;
        }
        padding: 10px;
        margin-bottom: 20px;
    }
    .dialogue_box_odd {
        clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 10% 95%, 5% 100%, 20% 95%, 0% 95%);
        background-color: $mainSecondary;
        width: 100%;

        @media (min-width: 768px) {
            clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 10% 90%, 5% 100%, 20% 90%, 0% 90%);
            width: 80%;
            margin-right: auto;
            margin-bottom: 5vh;
        }
        padding: 10px;
        margin-bottom: 20px;
    }

    .inside_dialogue_box {
        text-align: center;
        margin: 2% 5% 18%;
        @media (min-width: 768px) {
            margin: 2% 5% 8%;
        }
        
    }
}