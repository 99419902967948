input, textarea {
    &:not(:placeholder-shown) {
        // &:valid {
        //     //
        // }
        &:invalid {
            border-bottom:solid 2px #db6565;
        }


    }



    // [type="date"]:before {
    //     content: attr(placeholder) !important;
    //     color: #aaa;
    //     margin-right: 0.5em;
        
    // }
    
    // &:placeholder-shown {
    // }

    // &:not(:checked){
        
    // }
}

// input[type="date"]:before {
//     content: attr(placeholder) !important;
//     color: #aaa;
//     margin-right: 0.5em;
// }
// input[type="date"]:focus:before,
// input[type="date"]:valid:before {
// content: "";
// }