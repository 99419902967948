@use "sass:list";
@use "sass:math";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


// Variables
@import 'variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';

//FONTAWESOME
@import '../css/fontawesome-free/css/all.css';

//BOXICONS
@import '~boxicons/css/boxicons.min.css';

//DATATABLES
@import '../css/datatables/dataTables.bootstrap4.css';
@import '../css/datatables/buttons.bootstrap4.css';

//Animate
@import '~animate.css/animate.min.css';

//owl.carousel
@import '~owl.carousel/dist/assets/owl.carousel.css';


//AOS
@import '~aos/src/sass/aos.scss';

//SIMPLE-MDE JS
@import '~simplemde/dist/simplemde.min.css';

//Custom CSS
@import 'theme/theme';