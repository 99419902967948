

//COLORS
$primary: #9546e3;
$primary-color: rgb(234, 217, 234);

$secondary-pink: #a76ca7;

$mainBg: #f0dcf0;
$mainSecondary: rgb(146, 82, 196); 
$lightSecondary: #fcf4ff;
$lightThird: #EAD9EA;

$mainThird: #704295;
$mainFourth: #5a2191;
$mainFourth-60:  rgba($mainFourth,0.6);
//SIZES
$logowidth: 180px;

//BUTTONS
$btn-dark-pruprle: #9E65DB;

//NAVIGATION
$nav-max-height: 100px;


// COLORS
$colors: 
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  )
;

$theme-colors: 
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  );