@import 'variables';

@import "utilities";

.admin-panel {
    @import 'admin/sb-admin-2.scss';
    @import 'admin/data-tables.scss';
    
}


// simpleMDE Override
.CodeMirror, .CodeMirror-scroll {
    min-height: 80px;
}

@import 'dev';
body {
    @import 'public/others';
}
#app {
    position: relative;
    
    @import 'nav';

    @import 'login';
    
    @import 'custom-btns';

    @import 'public/landing';

    @import 'public/blog';

    @import 'public/contact';
    
    @import 'public/services';

    @import 'public/receipts';

    @import 'public/faq';

    @import 'public/footer';

    @import 'public/newsletter';

}

@import 'user/families';
@import 'user/admissionForm';

@import 'user/followups';

@import 'user/pay';

@import 'user/testimonie';

@import 'admin/products';

@import "animations/animations";
main {
    background-color: $lightSecondary;
    min-height: calc(100vh - 102px);
}

