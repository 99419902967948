.services {
    padding-bottom: 2.5rem;
    
    .title {
        color: $mainThird;
    }
    .services-container {
        a.card-link {
            color: $mainFourth;
            text-decoration: none;
            &:hover{
                color: $mainFourth;
                text-decoration: none;
            }

            &:visited {
                color: $mainFourth;
                text-decoration: none;
            }
        }

        .card {
            &.list {
                height: 100%;
            }
            border: 3px solid $mainFourth;
            box-shadow: 0 0 15px #00000050;
            transition: transform 0.3s ease-in-out;
            
            &:hover{
                transform: translateY(-5px)
            }
           
           
            .card-title {
                background-color: $mainFourth;
                color: $white;
                
                padding: 0.6rem;
                margin-bottom: 0;
                text-align: center;
                min-height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .card-image {
                position: relative;
                max-height: 380px;
                overflow: hidden;
                display: flex;
                justify-content: stretch;
                align-items: stretch;
                background-color: $mainFourth;

                img {
                    margin: 0;
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }
                border-bottom: 5px solid $mainFourth;

                .card-price {
                    position: absolute;
                    background-color: $mainFourth;
                    color: $white;
                    text-align: center;
                    padding: 0.6rem;
                    margin-bottom: 0;
                    width: 50%;
                    bottom: 3%;
                    letter-spacing: -1px;
                    * {
                        margin-bottom: 0;
                    }

                    font-size: larger;
                }
            }
            .card-body {
                background-color: #fcf4ff;
                font-size: large;
                text-align: justify;
            }

            .card-button {
                width: 100%;
                background-color: $mainFourth;
                border: none;
                font-size: larger;
                text-align: center;
                padding: 20px 10px;
                color: $white;
                text-transform: uppercase;
                transition: all 0.3s ease-in-out;            
                text-decoration: none;
                &:hover{
                    background-color: lighten($mainFourth, 10%);
                    color: $white;
                    text-decoration: none;
                }

                &:visited {
                    color: $white;
                    text-decoration: none;
                }
            }
        
        }

        .primary-link {
            color: $mainFourth;

            &:hover,&:visited {
                color: $mainFourth;
            }
        }

        .separator {
            background-color: $mainThird;
            color: white;
            margin: 0 -2em 0.5em;
            padding: 10px 2.1em;
            border-radius: 3px;
            text-align: center;
        }

        .note {
            background-color: $mainFourth-60;
            color: white;
        }
    }

    .service-description {
        font-size: x-large;
    }



    $service-hover-duration: 3s;
    .service {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .img-circle {
            border-radius: 50%;
            overflow: hidden;
            border: solid 5px $mainThird;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all (0.1*$service-hover-duration) ease-in-out;
            max-width: 120px;
            
            @media (min-width:576px) {
                max-width: 150px;
            }

            @media (min-width:768px) {
                max-width: 200px;
            }

            @media (min-width:992px) {
                max-width: 230px;
            }

            @media (min-width:1200px) {
                max-width: 270px;
            }

            @media (min-width:1200px) {
                max-width: 300px;
            }                
        }
        &:hover {
            text-decoration: none;
            
            .img-circle {
                border: solid 5px $primary;
                box-shadow: 0 0 20px rgba($mainThird, 0.5);
            }

            img {
                transition-delay: (0*$service-hover-duration);
                transform: scale(1.25);
            }

            .service-title {
                transition-delay: (0*$service-hover-duration);
                transform: scale(1.25);
                color: $primary;
            }
        }
        img {
            --service-width: 100px;
            transform: scale(1.05);
            transition-delay: 0.3*$service-hover-duration;
            transition: all (0.1*$service-hover-duration) ease-in-out;
            border-radius: 50%;
            overflow: hidden;
            aspect-ratio: 1 / 1;
            

            object-fit: cover;
        }
        padding: 0.5rem;

        .service-title {
            transition-delay: 0.3*$service-hover-duration;
            transition: all (0.1*$service-hover-duration) ease-in-out;
            color: $mainThird;
            padding: 0.5rem 0 0 0;
            font-size: 1rem;
        }
    }


    .box {
        position: relative;
        margin: 0 0 25px 0;
        border: solid $mainFourth 3px;
        border-radius: 3px;
        overflow: hidden;
        background-color: $lightSecondary;
        box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
        transition: transform 0.3s ease-in-out;

        @include media-breakpoint-up(md) {
            padding: 0 1em 0 300px;
        }


        a {
            color: $mainSecondary;
            &:hover {
                text-decoration: none;
            }
        }


        
        @keyframes rotate-in {
            
            0% {
                transform: translate(0%,-100%);
            }
            100% {
                transform: translate(0%,0%);
            }
        }
        
        @include media-breakpoint-up(md) {
            @keyframes rotate-in {
            
                0% {
                    transform: translate(-100%,0) rotate(-180deg);
                }
                100% {
                    transform: translate(0%,0) rotate(10deg);
                }
            }

        }


        
        // @keyframes peak-in {
        //     0% {
        //         transform: translate(0%,0) rotate(10deg);
        //     }
        //     100% {
        //         transform: translate(10%,0) rotate(45deg);
        //     }
        // }

        .image-section {
            position: relative;   
            overflow: hidden;
            // transform: translate(0,-100%);
            transition: transform 0.5s ease-in-out;
            top: 0%;
            left: 0;
            width: 100%;
            min-width: 100px;
            min-height: 100px;
            // max-height: 200px;
            height: 100%;
            width:100%;
            border-bottom: solid $mainFourth 2px;
            
            
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: $mainFourth;
            }


            @include media-breakpoint-up(sm){
                border-right: solid $mainFourth 2px;
                min-width: 100px;
                min-height: 100px;
                // max-height: 250px;
            }
            
            @include media-breakpoint-up(md) {
                aspect-ratio: 1 / 1;
                position: absolute;   
                bottom: -20px;
                left: -35px;

                min-width: 100px;
                max-width: 300px;
                min-height: 100px;
                // max-height: 300px;
                
                &.circle {
                    transform: translate(-100%,0) rotate(-180deg);
                    border-radius: 50%;
                }
            }

            // animation: rotate-in 1s ease-in-out forwards;

            &.aos-animate {
                transform: translate(0%,0);
                
                @include media-breakpoint-up(md) {
                    transform: translate(0%,0) rotate(10deg);
                }

            }
            img {
                object-fit: cover;
                min-width: 100%;
                width:100%;
                max-width: 100%;
                height: 200px;
                min-height: 100%;
            }
        }
        .title-section {
            color: $mainFourth;
            margin: 1em
            ;

            @include media-breakpoint-up(sm) {
                left: 300px;
                margin: 1em 0 0;
                
            }
            
            @include media-breakpoint-up(md){
                position: absolute;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                top: 0;
                right: 0;
                left: 0;
            }
            


            .age-range {
                padding: 5px 10px;
                margin: 5px;
                color: $mainFourth;
                border-radius: 3px;
                margin-bottom: 5px;

                &.fill {
                    background-color: rgba($mainFourth,0.7);
                    color: white;
                }
            }
        }

        .pricing-section {

            display: flex;
            position: absolute;
            justify-content: space-around;
            align-items: center;
            // transform: translate(0,110%);
            transition: transform 1s ease-in-out;
            padding: 3px 3%;
            bottom: 0;
            right: -3%;
            left: -3%;
            width: 106%;
            color: white;
            background-color: $mainFourth;

            &::before {
                content: attr(data-before-text) " ";
                color: white;
                text-transform: capitalize;
            }
            
            @include media-breakpoint-up(md){
                display: flex;
                position: absolute;
                justify-content: space-around;
                align-items: center;
                // transform: translate(0,110%);
                transition: transform 1s ease-in-out;
                padding: 3px 3%;
                bottom: 0;
                right: -3%;
                left: -3%;
                width: 106%;
                color: white;
                background-color: $mainFourth;
            }
        }

        &:hover {

            transform: scale(1.01);

            .image-section {
                transform: scale(1.05);
                
                @include media-breakpoint-up(md) {
                    
                    &.cirlce {
                        transform: translate(1%,0) rotate(25deg);
                    };
                }

            }

            // .pricing-section {
            //     // transform: translate(0,0);
            //     // transition-delay: 0.5s;
            //     @include media-breakpoint-up(md) {
            //         // silence is golden
            //     }
            // }

        }

        .description-section {
            padding: 5px;
            ul {
                columns: 2;
            }
            margin-bottom: 30px;
        }
    }
}