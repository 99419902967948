.dataTables_wrapper {
    display: flex;
    flex-wrap: wrap;
    
    .dataTables_length {
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        label {
            // background-color: rgba($primary,0.3);
            
            padding-left: 0.5em;
            border-radius: 3px;
            padding: 3px 0.5em;        
        }
        select {
            // #code
        }
    }

    .dataTables_filter{
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        label {
            // background-color: rgba($primary,0.3);
            
            padding-left: 0.5em;
            border-radius: 3px;
            padding: 3px 3px 3px 0.5em;

        }
        input {
            // border: 2px solid rgba($primary,0.3);
            border-radius: 3px;
        }
    }

    .dataTable {
        flex: 0 0 100%;

    }

    .dataTables_info {
        flex: 0 0 100%;
    }

    .dataTables_paginate,.paging_simple_numbers {
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 3px 0 5px;
        .paginate_button,span {
            padding: 0.25rem 0.5rem;
            border-radius: 3px;
            margin: 0 1px;
            color: $primary;
            border: 2px solid $primary;
            transition: all 0.2s ease-in-out ;
            &:hover {
                &:not(.disabled){
                    cursor: pointer;
                    border: 2px solid darken($primary, 10%);
                }
                text-decoration: none;
            }
            &:not(.disabled):active {
                border: 2px solid darken($primary, 12.5%);
                text-decoration: none;
            }
            &.previous,&.next{
                padding: 0.24rem 0.48rem;

                &:hover {
                    &:not(.disabled){
                        color: white;
                        background-color: darken($primary, 7.5%);
                        border: 2px solid darken($primary, 10%);
                    }
                    text-decoration: none;
                }
                &:not(.disabled):active {
                    background-color: darken($primary, 10%);
                    border: darken($primary, 12.5%);
                    text-decoration: none;
                }
            } 
 
            

            &.disabled {
                display: none;
            }
        }
        span {
            border-radius: 3px;
            border: 2px solid $primary;
            padding: 0.25rem 0;
            margin: 0!important;

            .paginate_button{
                border: none !important;
                border-radius: 2px;
                transition: 0.1s ease-in-out;
                &.current {
                    background-color: darken($primary, 10%);
                    border: darken($primary, 12.5%);
                    text-decoration: none;
                    color: $white;
                }

                &:hover {
                    &:not(.disabled){
                        color: white;
                        background-color: darken($primary, 7.5%);
                        border: 2px solid darken($primary, 10%);
                    }
                    text-decoration: none;
                }
                &:not(.disabled):active {
                    background-color: darken($primary, 10%);
                    border: darken($primary, 12.5%);
                    text-decoration: none;
                }

            }
        }
    }
}