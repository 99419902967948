@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes dtb-spinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes dtb-spinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes dtb-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3;
}
div.dt-button-info > div {
  padding: 1em;
}

button.dtb-hide-drop {
  display: none !important;
}

div.dt-button-collection-title {
  text-align: center;
  padding: 0.3em 0 0.5em;
  font-size: 0.9em;
}

div.dt-button-collection-title:empty {
  display: none;
}

span.dt-button-spacer {
  display: inline-block;
  margin: 0.5em;
  white-space: nowrap;
}
span.dt-button-spacer.bar {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  padding-left: 0.5em;
}
span.dt-button-spacer.bar:empty {
  height: 1em;
  width: 1px;
  padding-left: 0;
}

div.dt-button-collection span.dt-button-spacer {
  width: 100%;
  font-size: 0.9em;
  text-align: center;
  margin: 0.5em 0;
}
div.dt-button-collection span.dt-button-spacer:empty {
  height: 0;
  width: 100%;
}
div.dt-button-collection span.dt-button-spacer.bar {
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 0;
}

div.dt-button-collection {
  position: absolute;
  z-index: 2001;
  width: 100%;
}
div.dt-button-collection div.dropdown-menu {
  display: block;
  z-index: 2002;
  min-width: 100%;
}
div.dt-button-collection div.dt-button-collection-title {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}
div.dt-button-collection.fixed.two-column {
  margin-left: -200px;
}
div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
div.dt-button-collection > :last-child {
  display: block !important;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}
div.dt-button-collection > :last-child > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
div.dt-button-collection.two-column {
  width: 400px;
}
div.dt-button-collection.two-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
div.dt-button-collection.three-column {
  width: 450px;
}
div.dt-button-collection.three-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
div.dt-button-collection.four-column {
  width: 600px;
}
div.dt-button-collection.four-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
div.dt-button-collection .dt-button {
  border-radius: 0;
}
div.dt-button-collection.fixed {
  max-width: none;
}
div.dt-button-collection.fixed:before, div.dt-button-collection.fixed:after {
  display: none;
}
div.dt-button-collection div.dt-btn-split-wrapper {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
div.dt-button-collection button.dt-btn-split-drop-button {
  width: 100%;
  color: #212529;
  border: none;
  background-color: white;
  border-radius: 0px;
  margin-left: 0px !important;
}
div.dt-button-collection button.dt-btn-split-drop-button:focus {
  border: none;
  border-radius: 0px;
  outline: none;
}
div.dt-button-collection button.dt-btn-split-drop-button:hover {
  background-color: #e9ecef;
}
div.dt-button-collection button.dt-btn-split-drop-button:active {
  background-color: #007bff !important;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }
  div.dt-buttons a.btn {
    float: none;
  }
}
div.dt-buttons button.btn.processing,
div.dt-buttons div.btn.processing,
div.dt-buttons a.btn.processing {
  color: rgba(0, 0, 0, 0.2);
}
div.dt-buttons button.btn.processing:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons a.btn.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: " ";
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

div.dt-btn-split-wrapper:active:not(.disabled) button, div.dt-btn-split-wrapper.active:not(.disabled) button {
  background-color: #5a6268;
  border-color: #545b62;
}
div.dt-btn-split-wrapper:active:not(.disabled) button.dt-btn-split-drop, div.dt-btn-split-wrapper.active:not(.disabled) button.dt-btn-split-drop {
  box-shadow: none;
  background-color: #6c757d;
  border-color: #6c757d;
}
div.dt-btn-split-wrapper:active:not(.disabled) button:hover, div.dt-btn-split-wrapper.active:not(.disabled) button:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

div.dataTables_wrapper div.dt-buttons.btn-group div.btn-group {
  border-radius: 4px !important;
}
div.dataTables_wrapper div.dt-buttons.btn-group div.btn-group:last-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
div.dataTables_wrapper div.dt-buttons.btn-group div.btn-group:first-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
div.dataTables_wrapper div.dt-buttons.btn-group div.btn-group:last-child:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
div.dataTables_wrapper div.dt-buttons.btn-group div.btn-group button.dt-btn-split-drop:last-child {
  border: 1px solid #6c757d;
}
div.dataTables_wrapper div.dt-buttons.btn-group div.btn-group div.dt-btn-split-wrapper {
  border: none;
}

div.dt-button-collection div.btn-group {
  border-radius: 4px !important;
}
div.dt-button-collection div.btn-group button {
  border-radius: 4px;
}
div.dt-button-collection div.btn-group button:last-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
div.dt-button-collection div.btn-group button:first-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
div.dt-button-collection div.btn-group button:last-child:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
div.dt-button-collection div.btn-group button.dt-btn-split-drop:last-child {
  border: 1px solid #6c757d;
}
div.dt-button-collection div.btn-group div.dt-btn-split-wrapper {
  border: none;
}

span.dt-button-spacer.bar:empty {
  height: inherit;
}

div.dt-button-collection span.dt-button-spacer {
  padding-left: 1rem !important;
  text-align: left;
}
