#followup-index
{
    h1,h2,h3,h4,h5,h6 {
        text-transform: uppercase;
        color: $mainFourth;
    }
    .btn-purple {
        background-color: $mainFourth;
        color: whitesmoke;
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(138, 67, 214,0.5);
            color: white;
        }
    }

    .card {
        .card-header {
            &:not(.disabled){
                color: white;
                background-color: $mainFourth;
                transition: all 0.2s ease-in-out;
                &:hover {
                    transform: scale(1.1);
                    text-decoration: none;
                    border-radius: 15px;
                }
            }
        
            &.disabled {
                color: #c0c0c0;
                background-color: $mainThird;
                &:hover{
                    text-decoration: none;
                }
            }
        }

    }

    .inverted {
        padding: 1rem 1.25rem;
        border-radius: 3px;
        background-color: $mainFourth;
        span {
            color: #FaF0F0;
        }
    }

}


.table-responsive {
    border-radius: 5px;
    
    &::-webkit-scrollbar {
        width: 15px;
        border-radius: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: $mainBg;
        box-shadow: inset #555 2px;
        border-radius: 5px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $mainThird;
        border-radius: 5px;
        
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $mainFourth;
        
        
    }
    
    table.colored {
        overflow-x: scroll;
        margin-bottom: 10px;

        thead {
            background-color: $mainFourth;
            color: $white;
            tr {
                border-radius: 5px 5px 0 0;
                overflow: hidden;
            }
            h1,h2,h3,h4,h5,h6{
                color:$white;
                margin-bottom: 0;
            }
            th {
                text-align: center;
                &:first-child:not(.no-sticky) {
                    position: sticky;
                    left: 0;
                    background-color: $mainFourth;
                    z-index: 2;
                    
                }

                &.fixed-left {
                    position: sticky;
                    left: 0;
                    background-color: $mainFourth;
                    z-index: 2;
                    
                }

                .button-box {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                }
            }
        }

        tbody {
            th {
                
                position: sticky;
                left: 0;
                z-index: 2;
                background-color: $mainBg;
                text-align: center;
                
                h1,h2,h3,h4,h5,h6{
                    color:$mainFourth;
                    font-weight: bold;
                    @media (min-width: 768px) {

                        min-width: 150px;   
                    }

                    margin: 0;
                }
                label {
                    color:$mainFourth;
                    padding: 5px;
                }
            }
            td {
                
                text-align: center;
                min-width: 100px;
                border: solid 1px $mainFourth;
                input, textarea {
                    border: none;
                }
            }
        }
    }
}
