form {
    h1,h2,h3,h4,h5,h6 {
        text-transform: uppercase;
        color: $mainThird;
    }
    .btn-purple {
        background-color: $btn-dark-pruprle;
        color: whitesmoke;
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(138, 67, 214,0.5);
            color: white;
        }
    }
    
    @import "validation";

    input,textarea {

        &.form-control {
            position: relative;
            border: 0;
            border-radius: 1px;
            border-bottom: solid 2px #9e65db;
            color: #50207c;
            background-color: #FFFFFF00;
            font-size: medium;
            &:focus {
                box-shadow: none;
                background-color: #FFFFFF30;
                border-bottom: solid 3px rgb(158, 101, 219);
            }
            &::placeholder{
                color: #909090;
            }
        }
    }

    label,p,span,small {
        color: #50207c;
    }
}