.blog {
    .post {

        .title {
            color: $mainFourth;
            font-size: 2.5rem;
        }

        border-width: 2px;
        border-color: $mainFourth;
        border-radius: 0;
        .post-image {
            background-color: $mainFourth;
        }

        .post-link {
            color: #000;
            &:hover {
                color: $primary;
                text-decoration: none;
            }
            &.title{
                font-size: xx-large;
            }
        }
    }
}