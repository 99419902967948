footer {
    background-color: $mainThird;
    background-color: $secondary-pink;
    color: $white;
    h1,h2,h3,h4,h5,h6 {
        color: $lightSecondary;
    }
    padding-top: 1rem;
    overflow: hidden;
    a {
        color: darken($primary-color,15%);
        transition: all 0.15s ease-in-out; 
        text-decoration: underline;
        &:hover {
            color: white;
        }
    }

    .footer-icon {
        color: $primary-color;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        &:hover {
            color: white;
            transform: scale(1.1);
            text-decoration: none;
        }
    }

}