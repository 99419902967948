#newsletter-section {

    &.thanks {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .toggle-section {
        display: none;
    }
}


#newsletter-popup {
    display: none;
    opacity: 0;
    
    &.show {
        position: fixed;
        top: 0;
        opacity: 1;
        background: none;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        
        z-index: 100000;
    }

    .overlay {
        position: absolute;
        top: 0;
        cursor: pointer;
        
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.8);
        z-index: 100;

    }

    .container {
        max-width: min(750px,80%);
        max-height: min(750px,90%);
        background-color: $lightThird;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0,0,0,0.7);
        overflow-y: auto;
        z-index: 100000;

    }




    .close {

        position: sticky;
        top: 0;
        right: 0;
        border-radius: 0 0 0 50px;
        background-color: $mainFourth;
        color: white;
        height: 40px;
        width: 40px;
        cursor: pointer;

        i {
            padding: 0 0 6px 10px;
        }
    }

    .toggle-section {
        display: none;
    }

    .never-show-again {
        cursor: pointer;
        font-size: x-small;
    }
}

.newsletter {
    h3 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: xx-large;

    }

    p {
        font-size: x-large;
        text-align: center;
    }
    
    label {
        font-size: small;
        font-weight: bold;

    }

    .big {
        font-size: larger;
    }

    .icons {
        position:relative;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        
        // i.fa-heart {
        //     font-size: 99px;
        // }

        i.fa-envelope {
        //     position: absolute;
        //     left: 29px;
        //     top: 25px;
        //     font-size: 42px;
                font-size: 99px;
        //     color: #EAD9FE;
        }
    }

    .newsletter-add-child-container,.delete-newsletter-child-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}