.contact {
    background-image: url('/img/contact.jpg');
    background-position: center;
    background-size: cover;
      /* Create the parallax scrolling effect */
    // background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin-bottom: -3rem;
    position: relative;
    color: $mainFourth;
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(#fff,0.3);
        background: rgba(#daC9FF, 0.75);
        z-index: 1;
    }

    * {
        z-index: 3;
    }



    h2 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 1.8rem;
        color: inherit;
        font-weight: 400;
        text-transform: uppercase;
        @media (min-width: 762px) {
            font-size: 2.3rem;
        }

        text-align: center;
    }

    a {
        color: inherit;
        font-size: smaller;
        text-decoration: none;
        &:hover{
            color: inherit;
            text-decoration: none;
        }

        @media (min-width: 992px){
            font-size: larger;
        }
    }

    .contact-form {
        input,textarea {
            &::placeholder {
                font-size: larger;
                color: #FAFAFA;
            }
        }
    }
}