.rating {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 50px;
    font-size: 50px;
    
    label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      cursor: pointer;
      
      &:last-child {
        position: static;
      }
      
      &:nth-child(1) {
        z-index: 5;
      }
      
      &:nth-child(2) {
        z-index: 4;
      }
      
      &:nth-child(3) {
        z-index: 3;
      }
      
      &:nth-child(4) {
        z-index: 2;
      }
      
      &:nth-child(5) {
        z-index: 1;
      }
      
      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        :focus:not(:checked) ~ .icon:last-child {
            color: $primary-color;
            text-shadow: 0 0 5px $mainFourth;
        }
      }
      
      .icon {
        float: left;
        color: transparent;
      }
      
      &:last-child .icon {
        color: $primary-color;
      }
    }
    
    &:not(:hover)
    {
        label {
            input {
                &:checked ~ .icon {
                    color: $mainFourth;
                }
            }
        }
    } 
    
    &:hover {
        label {
            &:hover {
                input ~ .icon {
                    color: $mainFourth;
                }
            } 
        }
    } 
  }
  
  
  
  
  