
#admissionForm {
    .control:checked ~ .conditional,
    #immigrant:checked ~ .conditional,
    #required-2:checked ~ .conditional
    #option-2:checked ~ .conditional {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
    
    .control:not(:checked) ~ .conditional,
    #immigrant:not(:checked) ~ .conditional,
    #required-2:not(:checked) ~ .conditional,
    #option-2:not(:checked) ~ .conditional {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    h1,h2,h3,h4,h5,h6 {
        text-transform: uppercase;
        color: $mainFourth;
    }
    .btn-purple {
        background-color: $mainFourth;
        color: whitesmoke;
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(138, 67, 214,0.5);
            color: white;
        }
    }
    
    // @import "validation";

    input,textarea {
        position: relative;
        border: 0;
        border-radius: 1px;
        border-bottom: solid 2px #9e65db;
        color: #50207c;
        background-color: #FFFFFF00;
        font-size: medium;
        &:focus {
            box-shadow: none;
            background-color: #FFFFFF30;
            border-bottom: solid 3px rgb(158, 101, 219);
        }
        &::placeholder{
            color: #909090;
        }

    }
    label,p,span,small {
    color: #50207c;
    }

    hr{
    border-top: 1px solid rgba(138, 67, 214,0.3);;
    }
    #questionary{
        ol {
            padding-inline-start: 15px;
        }
    }
    #threeYearsOldSection {
        visibility: hidden;
        display: none;
        &.active {
            visibility: visible;
            display: flex;
        }
    }
    .baby-data {
        margin-left: -1.25rem;

    }

    .inverted {
        padding: 1rem 1.25rem;
        border-radius: 3px;
        background-color: $mainFourth;
        span {
            color: #FaF0F0;
        }
    }

    .form-group {
        label {
            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    .first-letter-capitalize {
        &::first-letter {
            text-transform: capitalize!important;
        }
    }

    .loader {
        margin: auto;
        border: 2px solid #EAF0F6;
        border-radius: 50%;
        border-top: 2px solid $mainFourth;
        width: 15px;
        height: 15px;
        animation: spinner 1.5s linear infinite;
    }

    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}
