.auth {
    
    .card {
        color: $mainFourth;
        border: solid 1px $mainFourth;
        background-color: #ffffff15;
        
        .card-header {
            color: $white;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            letter-spacing: 0.25rem;
            background-color: $mainFourth;
        }

        input,textarea {
            position: relative;
            border: 0;
            border-radius: 1px;
            border-bottom: solid 2px #9e65db;
            color: #50207c;
            background-color: #FFFFFF00;
            font-size: medium;
            &:focus {
                box-shadow: none;
                background-color: #FFFFFF30;
                border-bottom: solid 3px rgb(158, 101, 219);
            }
            &::placeholder{
                color: #909090;
            }

        }
        label,p,span,small {
        color: #50207c;
        }

        hr{
        border-top: 1px solid rgba(138, 67, 214,0.3);;
        }

    }
}

// .sidebar {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     top: 0;
//     margin-top: 102px;
//     background-color: $mainSecondary;
//     height: calc(100% - var($nav-max-height));
//     transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
//     transition-property: opacity, width;
//     box-shadow: 0.25rem 0 0.5rem rgba(0,0,0,0.3);
   
//     opacity: 0.9;
//     width: 5%;
//     ul {

//         li {
//             min-height: 25px;
//             padding: 0 0.5rem;
    
//             a {
//                 display: flex;
//                 justify-items: start;
//                 text-align: center;
    
//                 i {
//                     font-size: xx-large;
//                     margin: 0 auto;
//                     transition: all 0.3s ease-in-out;
//                     transition-delay: 0.25s;
//                 }
                
        
//                 span {
//                 }
//             }
    
            
//         }
     

//     }

//     &.active {
//         opacity: 1;
            
//         @include media-breakpoint-up(md) { 
//             width: 42%;
//         }

//         @include media-breakpoint-up(lg) { 
//             width: 20%;
//         }

//         ul {
//             li {        

//                 a {
//                     i {
//                         margin: 0 10px;
//                     }
//                     width: 100%;
//                     span {
//                         animation: 'hideSpan' 0.3s linear;
//                     }
//                 }
    
//             }
//         }
        
//     }

   
// }

// @keyframes hideSpan {
//     0% { opacity: 1;}
//     100% { opacity: 0; display: none;}
// }