.navbar {

    margin:0;
    padding: 0 1rem 0.5rem;
    box-shadow: 0 0 15px 15px rgba(0,0,0,0.15);
    @include media-breakpoint-up(md) { 
        padding: 0 1rem;
    }

    &.bg-main {
        background-color: $primary-color;
    }
    .nav-item {
        .nav-link {
            color: $mainThird;
            height: ($logowidth * 0.25);
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: color 0.1s linear;
            text-align: center;
            position: relative;
            font-size: medium;
            padding: 1rem 1.5rem;
            @include media-breakpoint-up(md) { 
                padding: 1rem 1rem;
                height: ($logowidth * 0.5);
            }

            &:hover, &.active, &.active-dropdown, &.curtain {
                color: $mainThird;
                z-index: 5;
                
                &::before {
                    transform: scaleY(1);
                    z-index: -1;
                }

            }
    
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: $lightSecondary;
                z-index: 1;
                transition: transform 0.5s;
                transition-timing-function: ease;
                transform-origin: 0 0;
                transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
                transform: scaleY(0);
    
            }
            
        }

        &.dropdown {
            &.show {
                .nav-link {                   
                    color: $mainThird;
                    z-index: 5;
        
                    &::before {
                        transform: scaleY(1);
                        z-index: -1;
                    }
                }


                .dropdown-menu {
                    background-color: $primary-color;
                    border: none;
                    border-top: 1px solid $primary;
                    border-radius: 1px;
                    padding: 0;
                    color: $mainThird;

                    @include media-breakpoint-up(lg) {
                        box-shadow: 0 0 2px rgba(0,0,0,0.5);
                    }

                    .dropdown-item {
                        padding: 1rem 1.5rem;
                        position: relative;    
                        background-color: #ffffff50;
                        &:hover {
                            color: $mainThird;
                            z-index: 5;
                            background-color: #ffffff50;
        
                            &::before {
                                transform: scaleY(1);
                                z-index: -1;
                            }
                        }
                
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: $lightSecondary;
                            z-index: 1;
                            transition: transform 0.5s;
                            transition-timing-function: ease;
                            transform-origin: 0 0;
                            transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
                            transform: scaleY(0);
                
                        }
                    }
                }
            }
        }
    }

    .navbar-brand {
        padding: 0;
        max-height: $nav-max-height;

        .navbar-logo {
            max-width: $logowidth;
            padding: 15px 0;
            margin: 0;

            // Extra small devices (portrait phones, less than 576px)
            @media (max-width: 575.98px) {
                max-width: 135px;
            }

            // Small devices (landscape phones, 576px and up)
            @media (min-width: 576px) and (max-width: 767.98px) {
                max-width: 150px;
            }

            // Medium devices (tablets, 768px and up)
            @media (min-width: 768px) and (max-width: 991.98px) {
                max-width: 185px;
            }
        }
    }
}

.navbar-icon {
    color: $mainThird;
    transition: all 0.3s ease-in-out;

    &:hover{
        color: $mainThird;
        transform: scale(1.2);
    }
}

#navbarNav {
    height: 100%;
}

.navbar-toggler {
    color: $mainThird;
    border-color: $mainThird;
}
.navbar-toggler-icon{
    color: $mainThird;
}
