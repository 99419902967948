.btn-cool {
    line-height: 2rem;
    padding: 5px 30px;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    transition: color 0.1s linear;
    text-align: center;
    position: relative;

    &:hover {
        color: #ffffff;
        z-index: 5;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #421569;
        border-radius: 3px;
        z-index: 1;
        transition: transform 0.5s;
        transition-timing-function: ease;
        transform-origin: 0 0;
        transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
        transform: scaleX(0);
    }

    &:hover {
        &::before {
        transform: scaleX(1);
        z-index: -1;
        }
    }
}


.btn-primary {
    background-color: $mainThird;
    color: whitesmoke;
    border-color: $mainFourth;
    &:hover {        
        background-color: $btn-dark-pruprle;
        color: white;
        border-color: $btn-dark-pruprle;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(138, 67, 214,0.5);
        background-color: $btn-dark-pruprle;
        color: white;
        border-color: $btn-dark-pruprle;
    }
    &:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle.show > .btn-primary.dropdown-toggle {
        background-color: $btn-dark-pruprle;
        color: white;
        border-color: $btn-dark-pruprle;        
    } 
}